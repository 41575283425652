<template>
  <div v-if="informationList.length" class="wrap" :class="{disabled: disabled}">
    <div v-for="information in informationList" :key="information.informationId" class="tw_dashboard_information">
      <div class="info_item" @click="showDetail(information)">
        <el-tooltip v-if="information.priorityFlg === PRIORITY_FLG.ON" placement="top" popper-class="mcu nowrap" :tabindex="-1">
          <div slot="content">{{getNewsType(information.newsType)}}</div>
          <span class="importance_icon" :class="[`news_type_${information.newsType}`]" />
        </el-tooltip>
        <span style="margin-top: 4px">{{information.informationTitle}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { NEWS_TYPE_VARIABLES, PRIORITY_FLG } from 'lib-tw-common';

export default {
  name: 'TwDashBoardInformation',
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      PRIORITY_FLG,
    }
  },
  computed: {
    informationList() {
      return this.$store.state.importantMessages;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch('GET_IMPORTANT_MASSAGES');
    },
    showDetail(message) {
      this.$router.push({
        name: 'NewsDetail',
        params: { informationId: message.informationId },
      });
    },
    // newsTypeの表示名を取得します
    getNewsType(type) {
      return _.get(_.find( NEWS_TYPE_VARIABLES, {code: type}), 'label');
    },
  }
};
</script>

<style lang="scss" scoped>
  .wrap {
    margin: 20px 10px;
    
    &.disabled {
    pointer-events: none;
    cursor: default!important;

      .tw_dashboard_information:after {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: rgba($color_black, 0.3);
        border-radius: 12px;
        content: '';
      }
    }
  }
  .tw_dashboard_information {
    position: relative;
    width: 1269px;
    max-width: 100%;
    min-height: 48px;
    background: linear-gradient(247.38deg, rgba(255, 255, 255, 0.56) -11.95%, rgba(255, 255, 255, 0.24) 109.53%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 4px 12px 30px rgba(201, 201, 201, 0.1);
    // backdrop-filter: blur(70px);
    border-radius: 12px;
    margin-bottom: 8px;

    .info_item{
      display: flex;
      padding: 8px 12px;
      font-family: Noto Sans;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;


      &:hover {
        opacity: .5;
      }

      .importance_icon {
        width: 32px;
        height: 32px;
        margin-right: 13px;
        flex-shrink: 0;
      }
    }
  }
</style>
