<template>
<div class="tw_dashboard_alert">
  <tw-table-system :schemas="schemas" :height="272" :nopager="true" :items="items" />
</div>
</template>

<script>
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/dashBoardAlertListSchema.json';
import items from '@/../public/alertList.json'; // スタブ

let _items = items.tradingFlowList;
_items = _items.map((o, i) => {
  return Object.assign({}, o, {noticeHistoryId: _items.length - i + ''});
});

export default {
  name: 'TwDashBoardAlert',
  props: {

  },
  components: {
    TwTableSystem,
  },
  data() {
    return {
      schemas: schemas,
    }
  },
  computed: {
    items() {
      return _items.slice(0, 10);
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_alert {
    position: relative;
    z-index: 2;
  }

  ::v-deep .empty_message {
    height: 350px;
    margin-bottom: 20px;
  }

  ::v-deep .tw_table {

    td .cell {
      padding: 0 12px;
    }

    .avatars {
      padding-left: 3px!important;
    }

  }

</style>
