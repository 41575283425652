<template>
<div class="tw_dashboard_news" :class="{edit: isEditMode, disabled: isDisabled}">
  <div v-if="informationList && informationList.length" class="news_list">
    <div class="news_list_item" v-for="information in informationList" :key="information.informationId" @click="onClick(information.informationId)">
      <div v-if="information.newFlg === INFORMATIONS_NEW_FLG.ON" class="new_icon">NEW</div>
      <div class="news_title">
        <el-tooltip v-if="information.priorityFlg === PRIORITY_FLG.ON" placement="top" popper-class="mcu nowrap" :tabindex="-1">
          <div slot="content">{{getNewsType(information.newsType)}}</div>
          <span class="importance_icon" :class="[`news_type_${information.newsType}`]" />
        </el-tooltip>
        <div class="title">{{information.informationTitle}}</div>
      </div>
      <div class="date">{{information.informationDateFrom | dateFormat}}</div>
    </div>
  </div>
  <div v-if="informationList && !informationList.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
</div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { INFORMATIONS_NEW_FLG, NEWS_TYPE_VARIABLES, PRIORITY_FLG } from 'lib-tw-common';

export default {
  name: 'TwDashBoardNews',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
  },
  data() {
    return {
      limit: 10,
      INFORMATIONS_NEW_FLG,
      PRIORITY_FLG,
    }
  },
  computed: {
    informationList() {
      return this.$store.state.newsList;
    },
    isNew() {
      return date => {
        const limit = dayjs().subtract(1, 'week').utc();
        return dayjs(date + 'T00:00:00Z').utc().isSameOrAfter(limit, 'day');
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch('GET_NEWS_LIST');
    },
    onClick(informationId) {
      this.$router.push({
        name: 'NewsDetail',
        params: { informationId: informationId },
      });
    },
    // newsTypeの表示名を取得します
    getNewsType(type) {
      return _.get(_.find( NEWS_TYPE_VARIABLES, {code: type}), 'label');
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_news.edit {
    pointer-events: none;
    position: relative;

    &.disabled:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(249, 249, 253, 0.5);
      content: '';
    }
  }

  .news_list{
    height: 267px;
    overflow-y: auto;
    background-color: $color_gray_100;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.12);
    // margin: 0 0 20px;
    padding: 5px 0;
  }
  .news_list_item {
    padding: 12px 21.5px 0;
    cursor: pointer;

    &:hover {
      background: $color_gray_300;
    }

    .news_title {
      display: flex;
      // align-items: center;
    }

    .new_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 30px;
      height: 18px;
      color: $color_white;
      font-weight: bold;
      font-size: 10px;
      line-height: 1px;
      padding: 4px 2px;
      background: $color_primary_blue;
      border-radius: 2px;
      margin-bottom: 4px;
    }

    .importance_icon {
      margin-right: 7px;
      flex-shrink: 0;
    }

    .title {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color_gray_800;
    }

    .date {
      font-size: 14px;
      line-height: 20px;
      color: $color_gray_400;
      padding-bottom: 12px;
      border-bottom: 1px solid #EBE9F1;
    }

    &:last-child .date {
      border: 0;
    }
  }

  .empty_message {
    height: 350px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-top: 33px;
  }
</style>
