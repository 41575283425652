<template>
  <div v-if="editor">
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
// eslint-disable-next-line import/no-extraneous-dependencies
import Document from '@tiptap/extension-document';
// eslint-disable-next-line import/no-extraneous-dependencies
import Paragraph from '@tiptap/extension-paragraph';
// eslint-disable-next-line import/no-extraneous-dependencies
import Text from '@tiptap/extension-text';
import Mention from '@tiptap/extension-mention';
import Link from '@tiptap/extension-link';

export default {
  name: 'TwChatContent',
  props: {
    content: String,
  },
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Link,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
        })
      ],
      editable: false,
      content: this.content,
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>