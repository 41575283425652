<template>
<div>
  <tw-header @edit-dashboard="editDashBoard" />
  <div id="dashboard" class="tw_container">
    <div class="flex" v-if="isEditMode">
      <div class="back" style="margin-bottom: 0" @click="back">
        <img src="@/assets/images/icons/chevron_left_gray800.svg">Back
      </div>
      <tw-button type="primary" size="medium" @click="back">OK</tw-button>
    </div>
    <tw-breadcrumbs v-else-if="breadcrumbs.length" :pages="breadcrumbs" />
    <tw-dash-board-information :disabled="isEditMode" />
    <tw-container-header :disabled="isEditMode" />
    <vuuri
      v-model="items"
      item-key="id"
      :get-item-width="getItemWidth"
      :get-item-height="getItemHeight"
      drag-enabled
      group-id="1"
      :options="options"
      :dragHandle="'.drag_handle'"
      @dragReleaseEnd="onUpdate"
    >
      <template #item="{ item }">
        <div v-if="item.name==='air'" class="dashboard_card" :class="[item.name]">
          <div class="inner" :class="{disabled: !visible[item.name]}">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Air Location')}}
              </div>
              <a v-if="!isEditMode" class="more window" href="https://www.flightradar24.com/" target="_blank" tabindex="-1">More</a>
            </header>
            <img src="@/assets/images/air_location.png" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='ship'" class="dashboard_card" :class="[item.name]">
          <div class="inner" :class="{disabled: !visible[item.name]}">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Shipping Location')}}
              </div>
              <a v-if="!isEditMode" class="more window" href="https://www.marinetraffic.com/" target="_blank" tabindex="-1">More</a>
            </header>
            <img src="@/assets/images/shipping_location.png" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='news'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.News')}}
              </div>
              <router-link v-if="!isEditMode" class="more" :to="{name: 'NewsList'}" tabindex="-1">More</router-link>
            </header>
            <tw-dash-board-news :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='comments'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Comments')}}
              </div>
            </header>
            <tw-dash-board-comments :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='mail'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Mail History')}}
              </div>
              <router-link v-if="!isEditMode" class="more" :to="{name: 'MailHistory'}" tabindex="-1">More</router-link>
            </header>
            <tw-dash-board-mail :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='blockchain'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <tw-dash-board-block-chain :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" style="right: 30px;" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='confirmation'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Confirmation(To)')}}
              </div>
            </header>
            <tw-dash-board-confirmation :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <!-- <div v-else-if="item.name==='alert'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />Alert
              </div>
            </header>
            <tw-dash-board-alert />
          </div>
        </div> -->
        <div v-else-if="item.name==='process'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Process List')}}
              </div>
            </header>
            <tw-dash-board-process :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='trading'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Trading Status')}}
              </div>
              <router-link v-if="!isEditMode" class="more" :to="{name: 'ListTrading'}" tabindex="-1">More</router-link>
            </header>
            <tw-dash-board-trading :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='calendar'" class="dashboard_card" :class="[item.name]">
          <tw-dash-board-calendar :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='partner'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.Partner Services')}}
              </div>
              <!-- <router-link v-if="!isEditMode" class="more" :to="{name: 'NewsList'}" tabindex="-1">More</router-link> -->
            </header>
            <tw-dash-board-partner-services :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
        <div v-else-if="item.name==='external'" class="dashboard_card" :class="[item.name]">
          <div class="inner">
            <header class="drag_handle">
              <div class="column">
                <i />{{$t('Dashboard.External Services Status')}}
              </div>
              <!-- <router-link v-if="!isEditMode" class="more" :to="{name: 'NewsList'}" tabindex="-1">More</router-link> -->
            </header>
            <tw-dash-board-external-services-status :isEditMode="isEditMode" :isDisabled="!visible[item.name]" />
          </div>
          <el-switch v-if="isEditMode" v-model="visible[item.name]" @change="onVisibleChange" />
        </div>
      </template>
    </vuuri>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import vuuri from 'vuuri';
import items from '@/dictionaries/dashboard.js';
import TwButton from '@/components/atoms/TwButton';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import TwDashBoardInformation from '@/components/organisms/TwDashBoardInformation';
import TwHeader from '@/components/organisms/TwHeader';
import TwContainerHeader from '@/components/organisms/TwContainerHeader';
import TwDashBoardNews from '@/components/organisms/TwDashBoardNews';
import TwDashBoardComments from '@/components/organisms/TwDashBoardComments';
import TwDashBoardBlockChain from '@/components/organisms/TwDashBoardBlockChain';
import TwDashBoardMail from '@/components/organisms/TwDashBoardMail';
import TwDashBoardConfirmation from '@/components/organisms/TwDashBoardConfirmation';
import TwDashBoardAlert from '@/components/organisms/TwDashBoardAlert';
import TwDashBoardProcess from '@/components/organisms/TwDashBoardProcess';
import TwDashBoardTrading from '@/components/organisms/TwDashBoardTradingStatus';
import TwDashBoardCalendar from '@/components/organisms/TwDashBoardCalendar';
import TwDashBoardPartnerServices from '@/components/organisms/TwDashBoardPartnerServices';
import TwDashBoardExternalServicesStatus from '@/components/organisms/TwDashBoardExternalServicesStatus';

export default {
  name: 'DashBoard',
  components: {
    TwButton,
    TwBreadcrumbs,
    TwDashBoardInformation,
    TwHeader,
    TwContainerHeader,
    vuuri,
    TwDashBoardNews,
    TwDashBoardComments,
    TwDashBoardBlockChain,
    TwDashBoardMail,
    TwDashBoardConfirmation,
    // eslint-disable-next-line vue/no-unused-components
    TwDashBoardAlert,
    TwDashBoardProcess,
    TwDashBoardTrading,
    TwDashBoardCalendar,
    TwDashBoardPartnerServices,
    TwDashBoardExternalServicesStatus,
  },
  data() {
    return {
      count: 0,
      items: [],
      options: {
        layout: {
          fillGaps: true,
          horizontal: false,
          alignRight: false,
          alignBottom: false,
          rounding: false
        }
      },
      isEditMode: false,
      visible: {},
    };
  },
  computed: {
    ...mapGetters(['isShipper']),
    isPic() {
      return this.$store.state.userInformationV2?.picFlg
    },
    userRoleCd() {
      return this.$store.state.userRoleCd;
    },
    companyRoles() {
      return this.$store.state.companyRoles;
    },
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard')},
      ];
    },
  },
  created() {
    this.initItems();
  },
  methods: {
    initItems() {
      const order = this.$store.getters.getDashBoardOrder;
      this.visible = this.$store.getters.getDashBoardVisible;
      this.items = _.orderBy(_.map(items, item => {
        const index = _.findIndex(order, id => {
          return id === item.id;
        });
        return {
          ...item,
          // index: index < 0 ? -1 : index // 新規追加分は先頭に
          index: index < 0 ? 99 : index // 新規追加分は末尾に
        };
      }), ['index', 'asc']);
      if (!this.isShipper && this.companyRoles.length > 0) this.rejectItem(3); // 取引ステータス（trading）は、輸出、輸入、三国間のみ表示
      if (!this.isPic && this.userRoleCd) this.rejectItem(9); // To確認中（Confirmation(To)）は、担当者ロールのユーザーのみ表示する
      this.items = _.reject(this.items, item => {return this.visible[item.name] === false});
    },
    onDeleteClicked(item) {
      const index = items.value.findIndex(value => value.id === item.id);
      items.value.splice(index, 1);
    },
    confirm(item) {
      this.$confirm('削除しますか？', '', {})
        .then(() => {
          this.onDeleteClicked(item);
        })
        .catch(() => {});
    },
    getItemWidth(item) {
      return `${item.width}px`;
    },
    getItemHeight(item) {
      return `${item.height}px`;
    },
    onUpdate() {
      this.$store.commit('SET_DASHBOARD_ORDER', _.map(this.items, item => item.id));
    },
    rejectItem(id) {
      this.items = _.reject(this.items, item => {return item.id === id});
    },
    editDashBoard() {
      this.isEditMode = true;
      const order = [..._.filter(this.$store.getters.getDashBoardOrder, id => {
        return this.visible[_.get(_.find(items, {id: id}), name)];
      }), ..._.reject(this.$store.getters.getDashBoardOrder, id => {
        return this.visible[_.get(_.find(items, {id: id}), name)];
      })];
      this.items = _.orderBy(_.map(items, item => {
        return {
          ...item,
          index: _.findIndex(order, id => {
            return id === item.id;
          })
        };
      }), ['index', 'asc']);
      if (!this.isShipper && this.companyRoles.length > 0) this.rejectItem(3); // 取引ステータス（trading）は、輸出、輸入、三国間のみ表示
      if (!this.isPic && this.userRoleCd) this.rejectItem(9); // To確認中（Confirmation(To)）は、担当者ロールのユーザーのみ表示する
    },
    onVisibleChange() {
      this.$store.commit('SET_DASHBOARD_VISIBLE', this.visible);
    },
    back() {
      this.isEditMode = false;
      this.initItems();
    }
  },
  watch: {
    companyRoles() {
      if (!this.isShipper) this.rejectItem(3); // 取引ステータス（trading）は、輸出、輸入、三国間のみ表示
    },
    userRoleCd() {
      if (!this.isPic) this.rejectItem(9); // To確認中（Confirmation(To)）は、担当者ロールのユーザーのみ表示する
    }
  }
};
</script>

<style lang="scss" scoped>
  #dashboard {
    position: absolute;
    margin: 0 auto 10px;
    padding: 0px 48px 20px;
    overflow-x: hidden;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 30px;
    }
  }
</style>

<style lang="scss">
#dashboard {
  .tw_breadcrumbs {
    margin-top: 31px;
    margin-left: 10px;
  }

  .back {
    margin-left: 10px;
  }

  .dashboard_card {
    // width: 408px;
    height: 360px;
    background: linear-gradient(247.38deg, rgba(255, 255, 255, 0.56) -11.95%, rgba(255, 255, 255, 0.24) 109.53%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 4px 12px 30px rgba(201, 201, 201, 0.1);
    // backdrop-filter: blur(70px);
    border-radius: 16px;
    padding: 20px 0;

    .inner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 0 20px;
    }

    .drag_handle {
      cursor: move;
    }

    .el-switch {
      position: absolute; 
      top: 31px;
      right: 21px;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 14px;

      .column {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      i {
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background-size: 20px auto;
        margin-right: 12px;
        background-color: $color_white;
        @include button_shadow;
      }

      .more {
        display: inline-block;
        height: 20px;
        padding-right: 20px;
        font-size: 14px;
        line-height: 20px;
        color: $color_dark_blue;
        background: url(../assets/images/icons/icon_more_arrow.svg) no-repeat right center;
        background-size: 16px auto;
        text-decoration: none;
        cursor: pointer;

        &.window {
          background-image: url(../assets/images/icons/icon_more_window.svg)
        }
      }
    }

    &.air .inner, &.ship .inner {
      overflow: visible;
      position: relative;

      img {
        object-fit: cover;
      }

      &.disabled img {
        opacity: .5;
      }
    }

    &.news .inner {
      padding: 0;
    }

    &.news header {
      padding: 0 20px;
    }

    &.air header i {
      background: url(../assets/images/icons/dashboard_air.png) no-repeat center;
      background-size: 24px auto;
    }

    &.ship header i {
      background: url(../assets/images/icons/dashboard_ship.png) no-repeat center;
      background-size: 24px auto;
    }

    &.news header i {
      background: #D189E1 url(../assets/images/icons/dashboard_news.png) no-repeat center;
    }

    &.comments header i {
      background: #84BCDF url(../assets/images/icons/dashboard_comment.png) no-repeat center;
    }

    &.mail header i {
      background: #8A52E4 url(../assets/images/icons/dashboard_mail.png) no-repeat center;
    }

    &.confirmation header i {
      background: #17C2A4 url(../assets/images/icons/dashboard_confirmation.png) no-repeat center;
    }

    &.alert header i {
      background: #E85B92 url(../assets/images/icons/dashboard_fire.png) no-repeat center;
    }

    &.process header i {
      background: #FD8236 url(../assets/images/icons/dashboard_list.png) no-repeat center;
    }
    &.trading header i {
      background: #FA8034 url(../assets/images/icons/dashboard_list.png) no-repeat center;
    }

    &.calendar header {
      i {
        background: #5097F8 url(../assets/images/icons/dashboard_calendar.png) no-repeat center;
      }
      .set_term {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 63px;
        box-shadow: 4px 12px 30px rgba(201, 201, 201, 0.1);
        // backdrop-filter: blur(70px);

        i, .term {
          border: 1px solid $color_white;
        }

        i {
          width: 28px;
          height: 28px;
          margin: 0px 1px;
          padding: 4px;
          box-shadow: none;
          cursor: pointer;
          &.back{
            border-radius: 6px 0px 0px 6px;
            background: url(../assets/images/icons/chevron_left_gray800.svg), linear-gradient(247.38deg, rgba(255, 255, 255, 0.56) -11.95%, rgba(255, 255, 255, 0.24) 109.53%), center no-repeat;
          }
          &.next{
            border-radius: 0px 6px 6px 0px;
            background: url(../assets/images/icons/chevron_right_gray800.svg), linear-gradient(247.38deg, rgba(255, 255, 255, 0.56) -11.95%, rgba(255, 255, 255, 0.24) 109.53%), center no-repeat;
          }
          &:hover {
            opacity: .5;
          }
        }

        .term{
          margin: 0 1px;
          padding: 4px 16px;
          color: $color_gray_800;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          background: linear-gradient(247.38deg, rgba(255, 255, 255, 0.56) -11.95%, rgba(255, 255, 255, 0.24) 109.53%);
        }
      }
    }

    &.partner header i {
      background: #1C0898 url(../assets/images/icons/dashboard_partner.png) no-repeat center;
      background-size: 24px auto;
    }

    &.external header i {
      background: #4B7473 url(../assets/images/icons/dashboard_external.svg) no-repeat center;
      background-size: 24px auto;
    }

    &.x2 {
      width: 840px;
    }

    .inner > img {
      width: 100%;
      border-radius:6px;
      @include drop_shadow;
    }
  }

  .demo-item {
    position: relative;
    height: 100%;
    width: 100%;
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 14px 20px 49px rgba(209, 217, 230, 0.67), -2px 22px 28px rgba(209, 217, 230, 0.48), -16px -6px 34px #ffffff;

    .grid-card-handle {
      display: block;
    }

    .delete-btn {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 10px;
      height: 30px !important;
      width: 30px !important;
      padding: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      opacity: 0;
      transform: scale(0.5);
    }

    &:hover {
      .delete-btn {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
.muuri-item {
  margin: 10px;
}

.drawer-card-foot {
  background: white;
  border: none;
}
</style>
