<template>
<div class="tw_dashboard_confirmation" :class="{edit: isEditMode, disabled: isDisabled}">
  <tw-table-system :schemas="schemas" :height="272" :nopager="true" :items="items" />
</div>
</template>

<script>
import { $api } from '@/store/ApiClient';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/dashBoardConfirmationToSchema.json';

export default {
  name: 'TwDashBoardConfirmation',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
    TwTableSystem,
  },
  data() {
    return {
      schemas: schemas,
      items: null,
    }
  },
  computed: {

  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      // bff_db_4 確認中ステータスプロセス一覧取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/process-to-confirm',
        }
      };

      $api
        .request(params)
        .then(res => {
          this.items = res.tradingFlowList;
        })
        .catch(err => {
          this.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_confirmation {
    position: relative;
    z-index: 2;

    &.edit {
      pointer-events: none;

      &.disabled:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(249, 249, 253, 0.5);
        border-radius: 8px;
        content: '';
      }
    }
  }

  ::v-deep .empty_message {
    height: 350px;
    margin-bottom: 20px;
  }

  ::v-deep .tw_table {

    td .cell {
      padding: 0 8px;
      cursor: default;
    }

    .avatars {
      padding-left: 3px!important;
    }

  }

</style>
