<template>
<div class="tw_dashboard_partner_services" :class="{edit: isEditMode, disabled: isDisabled}">
  <div v-if="items && items.length" class="partner_services_list">
    <div class="item" v-for="item in items" :key="item.id" @click="onClick(item.url)">
      <div class="item_inner">
        <figure v-if="item.thumbnail">
          <img :src="item.thumbnail" alt="item.name">
        </figure>
        <div style="flex:1">
          <div class="title">{{item.title}}</div>
          <div class="contents" v-html="sanitize($t(`Dashboard.${item.contents}`))" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="items && !items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
</div>
</template>

<script>

const items = [];

export default {
  name: 'TwDashBoardExternalServicesStatus',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
  },
  data() {
    return {
      limit: 10,
      items: items,
    }
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    onClick(url) {
      this.$router.push({
        name: 'WindowOpen',
        params: { url: url },
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_partner_services.edit {
    pointer-events: none;
    position: relative;

    &.disabled:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(249, 249, 253, 0.5);
      content: '';
    }
  }

  .partner_services_list {
    max-height: 267px;
    border-radius: 8px;
    overflow-y: auto;
    background-color: transparent;
    padding: 0;
    @include card_shadow;
  }
  .item {
    padding: 0 20px;
    cursor: pointer;
    background: white;

    .item_inner {
      display: flex;
      border-top: 1px solid #EBE9F1;
      padding: 12px 0;
      font-size: 12px;
    }

    &:first-child {

      .item_inner {
        padding-top: 20px;
        border: 0;
      }
    }

    &:hover {
      background: $color_gray_300;
    }

    figure {
      width: 72px;
      height: 72px;
      flex-shrink: 0;
      margin: 0 8px 0 0;
      
      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .title {
      min-height: 20px;
      font-weight: 700;
      margin: 0 0 6px;
      color: $color_dark_blue;
      padding: 2px 24px 0 0;
      background: url(../../assets/images/icons/icon_external_link.svg) no-repeat right top;
      background-size: 20px auto;
      line-height: 1.5;
      display: inline-flex;
      align-items: center;
    }

    .contents {
      color: $color_gray_800;
      line-height: 1.4;
    }
  }

  .empty_message {
    height: 350px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-top: 33px;
  }
</style>
