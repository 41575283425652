<template>
<div class="tw_dashboard_partner_services" :class="{edit: isEditMode, disabled: isDisabled}">
  <div v-if="items && items.length" class="partner_services_list">
    <div class="item" v-for="item in items" :key="item.id" @click="onClick(item.url)">
      <div class="item_inner">
        <figure>
          <img :src="item.thumbnail" :alt="item.name">
        </figure>
        <div style="flex: 1">
          <div class="title">{{item.title}}</div>
          <div class="contents" v-html="sanitize(item.contents)" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="items && !items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
</div>
</template>

<script>
export default {
  name: 'TwDashBoardPartnerServices',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
  },
  data() {
    return {
      limit: 10,
      items: [
        {
          id: '1',
          name: 'Trade Search',
          title: 'Trade Search',
          contents: 'Trade Searchは、デロイト トーマツ税理士法人が提供するクラウドベースのHSコード検索エンジンであり、通関業者、輸出入者のDX／オンラインオペレーションを支援するITソリューションです。',
          thumbnail: 'images/logo_deloitte.png',
          url: 'https://tradesearch.deloitte.jp/',
        },
        {
          id: '2',
          name: '国カテゴリーと引受方針',
          title: '国カテゴリーと引受方針',
          contents: '日本政府の貿易保険機関であるNEXIでは、国・地域ごとのリスクカテゴリー、及び貿易保険の引受方針を公表しています。',
          thumbnail: 'images/logo_nexi.png',
          url: 'https://www.nexi.go.jp/cover/',
        },
        {
          id: '3',
          name: 'カントリーレビュー',
          title: 'カントリーレビュー',
          contents: '各国の情勢に加えて、カントリーリスク分析の情報を提供しています。',
          thumbnail: 'images/logo_nexi.png',
          url: 'https://www.nexi.go.jp/webmagazine/'
        },
        {
          id: '4',
          name: 'ONESOURCE Global Trade',
          title: 'ONESOURCE Global Trade',
          contents: 'ONESOURCE Global Tradeは、トムソン・ロイターの国際貿易管理システムで、業務を一元化・効率化・自動化し、コンプライアンスリスクの軽減、及びコスト削減を支援するソリューションです。',
          thumbnail: 'images/logo_thomsonreuters.png',
          url: 'https://www.thomsonreuters.co.jp/ja/products-services/global-trade-overview.html?cid=746770&chl=na&sfdccampaignid=7014O000001Jnk1QAC'
        },
        {
          id: '5',
          name: '海外の食品規則チェックサイト',
          title: '海外の食品規則チェックサイト',
          contents: '農林水産省では、農林水産物・食品を海外へ輸出する際に参考となる、各国・地域の要件・規制等に関する情報を提供しています。',
          thumbnail: 'images/logo_maff.png',
          url: 'https://export-regulations.maff.go.jp/'
        }
      ]
    };
  },
  created() {
    this.items = this.shuffleItems(this.items);
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    onClick(url) {
      this.$router.push({
        name: 'WindowOpen',
        params: { url: url },
      });
    },
    shuffleItems(array) {
      let currentIndex = array.length;
      let randomIndex

      while (0 < currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }
      return array
    }
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_partner_services.edit {
    pointer-events: none;
    position: relative;

    &.disabled:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(249, 249, 253, 0.5);
      content: '';
    }
  }

  .partner_services_list {
    max-height: 267px;
    border-radius: 8px;
    overflow-y: auto;
    background-color: transparent;
    padding: 0;
    @include card_shadow;
  }
  .item {
    padding: 0 20px;
    cursor: pointer;
    background: white;

    .item_inner {
      display: flex;
      align-items: center;
      border-top: 1px solid #EBE9F1;
      padding: 12px 0;
      font-size: 12px;
    }

    &:first-child {

      .item_inner {
        padding-top: 20px;
        border: 0;
      }
    }

    &:hover {
      background: $color_gray_300;
    }

    figure {
      width: 72px;
      height: 72px;
      flex-shrink: 0;
      margin: 0 14px 0 0;

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 50%;
        @include card_shadow;
      }
    }

    .title {
      min-height: 20px;
      font-weight: 700;
      margin: 0 0 6px;
      color: $color_dark_blue;
      padding: 2px 24px 0 0;
      background: url(../../assets/images/icons/icon_external_link.svg) no-repeat right top;
      background-size: 20px auto;
      line-height: 1.5;
      display: inline-flex;
      align-items: center;
    }

    .contents {
      color: $color_gray_800;
      line-height: 1.4;
    }
  }

  .empty_message {
    height: 350px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-top: 33px;
  }
</style>
