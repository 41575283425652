<template>
<div class="tw_dashboard_mail" :class="{edit: isEditMode, disabled: isDisabled}">
  <tw-table-system :schemas="schemas" :height="269" :nopager="true" :items="items" />
</div>
</template>

<script>
import dayjs from 'dayjs';
import { INITIAL_VIEW_FLG } from 'lib-tw-common';
import { formatUtcDate } from '@/utils/searchUtil.js';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/dashBoardMailListSchema.json';

export default {
  name: 'TwDashBoardMail',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
    TwTableSystem,
  },
  data() {
    return {
      schemas: schemas,
    }
  },
  computed: {
    items() {
      return this.$store.state.mailList;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const query = {
        sendStartDate: formatUtcDate(dayjs().subtract(30, 'day').toDate()),
        sendEndDate: formatUtcDate(new Date()),
        initialFlag: INITIAL_VIEW_FLG.OFF,
      }
      this.$store.dispatch('GET_MAIL_LIST', query);
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_mail {
    position: relative;
    z-index: 2;

    &.edit {
      pointer-events: none;

      &.disabled:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(249, 249, 253, 0.5);
        border-radius: 8px;
        content: '';
      }
    }
  }

  ::v-deep .empty_message {
    height: 350px;
    margin-bottom: 20px;
  }

  ::v-deep .tw_table {

    tr td:first-child div.cell {
      padding-left: 12px;
    }

    td .cell {
      padding: 0 6px;
      cursor: default;
    }

    .avatars {
      padding-left: 3px!important;
      // figure.avatar {
      //   margin-left: -6px;
      // }
      // &>div {
      //   display: none;
      // }
    }

    td .cell span.customerName {
      justify-content: center;

      figure.avatar.table {
        margin: 0;
      }
    }

    td .cell span.customerName:after {
      content: none;
    }
  }

</style>
