<template>
<div class="tw_dashboard_block_chain" :class="{disabled: isDisabled}">
  <div class="video-wrap drag_handle">
    <div class="video_inner">
      <video src="@/assets/movies/bc_dashboard.mp4" autoplay playsinline muted></video>
    </div>
  </div>
  <router-link class="more" :is="isEditMode ? 'span' : 'router-link'" :to="{name: 'Archive'}">
    <footer :class="{overflow: bcCommitCount > 999}">
      <div class="blockchain_committed">
        <div class="icon">This Month</div>
        No.of Blockchain Committed
      </div>
      <div class="no">{{bcCommitCount}}</div>
    </footer>
  </router-link>
</div>
</template>

<script>
import { $api } from '@/store/ApiClient';

export default {
  name: 'TwDashBoardBlockChain',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  data() {
    return {
      bcCommitCount: 0,
    }
  },
  computed: {
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      // bff_db_3 取引ステータス一覧取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/bc-commit-count',
        }
      };

      $api
        .request(params)
        .then(res => {
          this.bcCommitCount = res.bcCommitCount;
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_block_chain {
    margin-top: 7px;
    border-radius: 6px;
    overflow: hidden;
    backdrop-filter: blur(70px);
    @include drop_shadow;
    .dashboard_card {
      padding-bottom: 0;
    }

    &.disabled .video_inner:after, &.disabled footer:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(249, 249, 253, 0.5);
      content: '';
    }

    &.disabled .video_inner:after {
      border-radius: 6px 6px 0 0;
    }

    &.disabled footer:after {
      border-radius: 0 0 6px 6px;
    }
  }

  .video-wrap {
    width: 367px;
    height: 224px;
    overflow: hidden;

    .video_inner {
      position: relative;
      width: 367px;
      height: 225px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .more { 
    text-decoration: none;
  }

  footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14.7px 20px 14.7px 16px;
    font-style: normal;
    font-weight: bold;
    color: $color_dark_blue;
    background: $color_white;

    .blockchain_committed {
      font-family: Noto Sans JP;
      font-size: 16px;
      line-height: 24px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 88.22px;
      height: 23.53px;
      margin-bottom: 4px;
      border-radius: 39px;
      font-family: Noto Sans;
      font-size: 12px;
      line-height: 20px;
      color: $color_white;
      background-color: $color_dark_blue;
    }

    .no {
      font-weight: 900;
      font-size: 58.7209px;
      line-height: 47px;
    }

    &.overflow {
      align-items: flex-end;

      .no {
        font-size: 45px;
        line-height: 1;
      }
    }
  }
</style>
