<template>
  <div class="tw_dashboard_comments" :class="{edit: isEditMode, disabled: isDisabled}">
    <div class="comments_list">
      <template v-if="chats && chats.length">
        <div class="comments_list_item" v-for="(comment, index) in chats" :key="index" @click="showDetail(comment)">
          <div class="from">
            <div v-if="isToYou(comment.chatDestinations)" class="from_icon to_you">To You</div>
            <div v-else class="from_icon section">Section</div>
            <div style="display: flex;align-items: center">
              <figure class="avatar table"><img :src="comment.userIconFrom"></figure>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{comment.userNameFrom}}</div>
                <div class="from_name">{{comment.userNameFrom}}</div>
              </el-tooltip>
            </div>
          </div>
          <div class="comment">
            <div class="comment_header">
              <div class="date">{{comment.createDate | dateTimeFormat}}</div>
              <span class="process">{{getProcessName(comment.processId)}}</span>
            </div>
            <div class="comment_detail">
              <tw-chat-content :content="comment.chatContents" />
            </div>
          </div>
        </div>
      </template>
      <div v-if="chats && !chats.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { processNames } from '@/dictionaries/map.js';
import TwChatContent from '@/components/molecules/TwChatContent';
import { getProcessNumber } from '@/utils/entity';

export default {
  name: 'TwDashBoardComments',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
    TwChatContent,
  },
  data() {
    return {
      editor: null,
      content: '',
      options: {always: true, smooth: false},
      open: true,
      chats: null,
    }
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      // bff_db_5 コメント取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/comment',
        }
      };

      $api
        .request(params)
        .then(res => {
          this.chats = _.orderBy(res.chats, ['createDate'], ['desc']);
        })
        .catch(err => {
          this.chats = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    getProcessName(processId) {
      return _.get(processNames[processId.replace(/\d/g, '')], 'nameExShort') + getProcessNumber(processId);
    },
    getLink(comment) {
      return {
        name: `Process${_.capitalize(comment.processId.replace(/\d/g, ''))}`,
        params: { entityId: comment.entityId, processSeq: comment.processSeq, _processId: comment.processId.toLowerCase() },
      };
    },
    isToYou(chatDestinations) {
      return _.some(chatDestinations, {userIdTo: this.userId});
    },
    showDetail(comment) {
      this.$router.push(this.getLink(comment));
    }
  }
};
</script>

<style lang="scss" scoped>
  .tw_dashboard_comments.edit {
    position: relative;
    pointer-events: none;

    .ProseMirror, .ProseMirror * {
      -webkit-user-select: none;
    }

    &.disabled:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(249, 249, 253, 0.5);
      content: '';
    }
  }
  
  #dashboard .dashboard_card .comments header {
    padding: 0 20px;
  }
  .comments_list {
    height: 275px;
    overflow-y: auto;
    background-color: $color_gray_100;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.12);
    margin: 0 -20px;
    padding: 5px 0;
  }
  .comments_list_item {
    display: flex;
    justify-content: space-between;
    padding: 12px 21.5px 12px;
    border-bottom: 1px solid #EBE9F1;
    cursor: pointer;

    .from {
      width: 78px;
    }

    .from_icon {
      min-width: 62px;
      max-width: 100%;
      min-height: 16px;
      padding: 2px 8px;
      text-align: center;
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_background;
      border-radius: 100px;
      margin-bottom: 6px;

      &.to_you {
        background: #EC82B5;
      }
      &.section {
        background: #62CED4;
      }
    }

    .from_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 20px;
    }

    .comment {
      width: calc(100% - 78px);
      padding-left: 8px;
    }

    .comment_header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 4px;
    }

    .date {
      width: 140px;
      font-size: 10px;
      line-height: 13.62px;
      color: $color_gray_600;
      text-align: right;
    }

    .process {
      width: 70px;
      margin-left: 24px;
      color: $color_dark_blue;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      cursor: pointer;
    }

    .comment_detail {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color_gray_800;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      max-height: 60px;
      -webkit-box-orient: vertical;
    
      ::v-deep .ProseMirror {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .empty_message {
    padding: 0 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-top: 33px;
  }

</style>
