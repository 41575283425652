<template>
<div class="inner" :class="{edit: isEditMode, disabled: isDisabled}">
  <header class="drag_handle">
    <div class="column">
      <i />{{$t('Dashboard.Calendar')}}
    </div>
    <div class="set_term">
      <i class="back" @click="prev" />
      <div class="term">{{searchForm.displayDateStart | dateFormat}} - {{searchForm.displayDateEnd | dateFormat}}</div>
      <i class="next" @click="next" />
    </div>
    <router-link v-if="!isEditMode" class="more" :to="linkParams" tabindex="-1">More</router-link>
    <span v-else style="width: 56px"></span>
  </header>
  <div class="tw_dashboard_calendar">
    <div class="el-form-item">
      <label for="">{{$t('Label.Owner/Section(From-To)')}}</label>
      <TwSelectSection v-model="searchForm.dashboardSectionId" :sections="formatSections" size="mini" @change="onSectionChange" />
      <!-- <el-select v-model="searchForm.dashboardSectionId" class="section" size="mini" :class="{multiple_selected: multipleSelect(searchForm.dashboardSectionId)}" placeholder="Select" @change="onSectionChange" multiple collapse-tags clearable filterable>
        <el-option
          v-for="section in formatSections"
          :key="section.value"
          :label="section.label"
          :value="section.value">
        </el-option>
      </el-select> -->
    </div>
    <el-calendar :first-day-of-week="firstDayOfWeek" :range="[searchForm.displayDateStart, searchForm.displayDateEnd]">
      <template
        slot="dateCell"
        slot-scope="{date}">
        <span class="date">
          {{ date | dayFormat }}
        </span>
        <template v-if="events(date).length">
          <el-dropdown v-for="(event, index) in events(date).slice(0, eventSliceLength(events(date)))" :key="index" style="width: 100%;">
            <template v-if="event.processId">
              <div class="event" :class="entityClass(event.entityProgressStatusCd)">
                <tw-alert-icon :limit="event.calculatedDueDate" :processProgressStatus="event.processProgressStatusCd" type="color" size="small" />
                <tw-entity-icon size="ex_small" :entityName="getEntityName(event.entityTypeCd)" />
                <span class="process" :class="{hasAlert: hasAlert(event.calculatedDueDate, event.processProgressStatusCd)}">{{getProcessName(event.processId)}}</span>
              </div>
              <el-dropdown-menu class="event_dropdown">
                <div class="event_detail" :class="entityClass(event.entityProgressStatusCd)" @click="showProcessDetail(event)">
                  <div class="inner">
                    <div class="icons">
                      <tw-alert-icon :limit="event.calculatedDueDate" :processProgressStatus="event.processProgressStatusCd" type="color" />
                      <tw-entity-icon :entityName="getEntityName(event.entityTypeCd)" />
                    </div>
                    <span class="process">{{getProcessName(event.processId)}}</span>
                    {{`${event.tradingName}（${event.tradingManageName}）`}}<br>
                    {{getDisplayNo(event)}}<br>
                  </div>
                </div>
              </el-dropdown-menu>
            </template>
            <template v-else>
              <div class="event reminder">
                <i/>
                <time v-if="event.reminderTime">{{event.reminderTime | timeFormatShort}}</time>
                <span class="process">{{event.reminderTitle}}</span>
              </div>
              <el-dropdown-menu class="event_dropdown">
                <div class="event_detail reminder" @click="showReminder(event, date)">
                  <div class="inner">
                    <div class="icons">
                      <i/>
                      <time>{{event.reminderTime | timeFormatShort}}</time>
                    </div>
                    <span class="process">{{event.reminderTitle}}</span>
                    <span class="reminder_text" v-html="sanitize(event.reminderText)" />
                  </div>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div v-if="events(date).length > 3" class="more_events">
            <span  @click="showMoreEvents(date)">{{events(date).length - 2}} More</span>
          </div>
        </template>
      </template>
    </el-calendar>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { CALENDAR_USE_CODE, ENTITY_PROGRESS_STATUS_VARIABLES, FROM_TO_TYPE, PROCESS_PROGRESS_STATUS, INITIAL_VIEW_FLG } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import TwAlertIcon from '@/components/atoms/TwAlertIcon';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwSelectSection from '@/components/atoms/TwSelectSection';
import { formatResponseDate, sectionForPulldown } from '@/utils/searchUtil.js';
import { entityNames, processNames } from '@/dictionaries/map.js';

export default {
  name: 'TwDashBoardCalendar',
  props: {
    isEditMode: Boolean,
    isDisabled: Boolean,
  },
  components: {
    TwAlertIcon,
    TwEntityIcon,
    TwSelectSection,
  },
  data() {
    return {
      sections: [],
      tradingFlowList: [],
      calendarReminders: [],
      searchForm: {
        dashboardSectionId: [],
        initialFlag: INITIAL_VIEW_FLG.ON, //INITIAL_VIEW_FLG.ON,
        displayDateStart: dayjs().startOf('week').toDate(),
        displayDateEnd: dayjs().startOf('week').add(13, 'day').toDate(),
      },
      firstDayOfWeek: 7, // 日曜始まり
      now: dayjs.utc(),
    };
  },
  computed: {
    companyId() {
      return this.$store.state.companyId;
    },
    multipleSelect() {
      return model => {
        return _.isArray(model) && model.length > 1;
      };
    },
    ownerSectionLists() {
      return this.$store.getters.getOwnerSectionLists;
    },
    formatSections() {
      return _.uniqBy([...this.ownerSectionLists, ...this.sections], 'value');
    },
    entityClass() {
      return entityProgressStatusCd => {
        return _.snakeCase(_.get(_.find(ENTITY_PROGRESS_STATUS_VARIABLES, {code: entityProgressStatusCd}), 'label'));
      };
    },
    getEntityName() {
      return entityTypeCd => {
        return _.get(_.find(entityNames, {code: entityTypeCd}), 'entityId');
      };
    },
    getProcessName() {
      return processId => {
        return _.get(processNames[processId.replace(/\d/g, '')], 'nameShort');
      };
    },
    events() {
      return date => {
        const reminders = _.filter(this.calendarReminders, o => {
          if (!o.reminderDate) {
            return false;
          }
          return dayjs(o.reminderDate.slice(0, 10)).isSame(date, 'day');
        });
        const events = _.filter(this.tradingFlowList, o => {
          if (!o.calculatedDueDate) {
            return false;
          }
          return dayjs(o.calculatedDueDate.slice(0, 10)).isSame(date, 'day');
        });
        return [...reminders, ...events];
      }
    },
    eventSliceLength() {
      return events => {
        if (events.length > 3) {
          return 2;
        } else {
          return 3;
        }
      }
    },
    getDisplayNo() {
      return event => {
        return event.invoiceNo || event.contractNo || '';
      }
    },
    hasAlert() {
      return (_limit, processProgressStatusCd) => {
        if (_limit || _.isNaN(processProgressStatusCd)) {
          return false;
        }

        // 完了時は表示しない
        if ([PROCESS_PROGRESS_STATUS.AGREED, PROCESS_PROGRESS_STATUS.DONE].includes(processProgressStatusCd)) {
          return false;
        }
        const limit = dayjs(formatResponseDate(_limit));
        if (limit.isToday() || limit.isYesterday()) {
          return true;
        }
        if (dayjs().isSameOrAfter(limit, 'day')) {
          return true;
        }
        return false;
      }
    },
    linkParams() {
      // 初期検索時はオーナーセクションリストの1件目
      const dashboardSectionId = this.searchForm.initialFlag === INITIAL_VIEW_FLG.ON ? (this.ownerSectionLists.length ? [this.ownerSectionLists[0].sectionId] : []) : this.searchForm.dashboardSectionId;
      return {name: 'ListTradingCalendar', params: {displayDateStart: this.searchForm.displayDateStart, displayDateEnd: this.searchForm.displayDateEnd, dashboardSectionId: dashboardSectionId}};
    },
  },
  watch: {
    companyId() {
      this.getSection(FROM_TO_TYPE.FROM);
      this.getSection(FROM_TO_TYPE.TO);
    }
  },
  created() {
    const queries = this.$store.getters.getQueries('DASHBOARD_CALENDAR');
    if (queries) {
      this.searchForm = queries;
      this.searchForm.displayDateStart = dayjs(this.searchForm.displayDateStart).startOf('week').toDate();
      this.searchForm.displayDateEnd = dayjs(this.searchForm.displayDateStart).startOf('week').add(13, 'day').toDate();
      // TODO 初期条件復活時に削除
      // this.searchForm.initialFlag = INITIAL_VIEW_FLG.OFF;
    }
    if (_.isEmpty(this.ownerSectionLists)) {
      this.$store.dispatch('GET_OWNER_SECTIONS');
    }
    this.fetch(this.searchForm.initialFlag);
    if (this.companyId) {
      this.getSection(FROM_TO_TYPE.FROM);
      this.getSection(FROM_TO_TYPE.TO);
    }
    this.onSectionChange = _.debounce(() => {
      this.fetch();
    }, 1000);
  },
  methods: {
    fetch(isInitial) {
      if (!isInitial) {
        this.searchForm.initialFlag = INITIAL_VIEW_FLG.OFF;
        this.$store.commit('SET_QUERIES', {key: 'DASHBOARD_CALENDAR', queries: _.cloneDeep(this.searchForm)});
      }

      const params = {
        // bff_db_2 ダッシュボードカレンダー取得BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/calendar',
          query: {
            displayDateStart: dayjs(this.searchForm.displayDateStart).subtract(3, 'day').format('YYYY-MM-DD'),
            displayDateEnd: dayjs(this.searchForm.displayDateEnd).add(3, 'day').format('YYYY-MM-DD'),
            dashboardSectionId: this.searchForm.dashboardSectionId,
            calendarUseCode: _.isEmpty(this.searchForm.dashboardSectionId) ? CALENDAR_USE_CODE.TRADING_LIST : CALENDAR_USE_CODE.DASHBOARD,
            initialFlag: this.searchForm.initialFlag
          }
        }
      };

      $api.request(params)
      .then(res => {
        this.tradingFlowList = res.tradingFlowList;
        this.calendarReminders = res.calendarReminders;
        if (!_.isEmpty(res.ownerSectionLists)) {
          this.$store.commit('SET_OWNER_SECTION_LISTS', _.map(res.ownerSectionLists, s => {
            return {
              ...s,
              label: s.sectionShortName,
              value: s.sectionId,
            }
          }));
        }
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    getSection(fromto) {
      const query = {
        fromToFlg: fromto,
        deleteType: 0,
      };

      // 取引先セクション取得BFF
      this.$store.dispatch('GET_SECTIONS', query)
      .then(res => {
        this.sections = this.sections.concat(sectionForPulldown(res.customerSectionList));
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    prev() {
      this.searchForm.displayDateStart = dayjs(this.searchForm.displayDateStart).subtract(2, 'week').toDate();
      this.searchForm.displayDateEnd = dayjs(this.searchForm.displayDateEnd).subtract(2, 'week').toDate();
      this.fetch();
    },
    next() {
      this.searchForm.displayDateStart = dayjs(this.searchForm.displayDateStart).add(2, 'week').toDate();
      this.searchForm.displayDateEnd = dayjs(this.searchForm.displayDateEnd).add(2, 'week').toDate();
      this.fetch();
    },
    showProcessDetail(event) {
      this.$router.push({
        name: `Process${_.capitalize(event.processId.replace(/\d/g, ''))}`,
        params: { entityId: event.entityId, processSeq: event.processSeq, _processId: event.processId.toLowerCase() },
      });
    },
    // イベントが三件以上あるときに週表示に切り替えます
    showMoreEvents(date) {
      const queries = { ...this.$store.getters.getQueries('TRADING_LIST_CALENDAR'), mode: 'WEEK' };
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(queries)});
      const linkParams = { name: this.linkParams.name, params: {...this.linkParams, displayDateStart: dayjs(date).startOf('week').toDate() }};
      this.$router.push(linkParams);
    },
    showReminder(event, date) {
      const queries = { ...this.$store.getters.getQueries('TRADING_LIST_CALENDAR'), mode: 'MONTH' };
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(queries)});
      const linkParams = { name: this.linkParams.name, params: {...this.linkParams, displayDateStart: dayjs(date).startOf('month').toDate(), reminderData: event }};
      this.$router.push(linkParams);
    },
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
  }
};
</script>

<style lang="scss" scoped>
  .inner.edit {
    .tw_dashboard_calendar, .set_term {
      pointer-events: none;
      position: relative;
    }

    &.disabled .tw_dashboard_calendar, &.disabled .set_term {
      opacity: .4;
    }
  }
  .tw_dashboard_calendar {
    
    .el-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      label {
        margin-right: 12px;
        font-size: 12px;
      }

      .el-select {
        width: 190px;
        flex-shrink: 0;
      }
      ::v-deep .el-select__tags {
        max-width: 158px!important;
      }
      ::v-deep .el-input__inner {
        width: 190px;
      }
    }

    ::v-deep .el-calendar {
      .el-calendar__header {
        display: none;
      }

      .el-calendar__body {
        padding: 0;
      }

      table {
        width: 798px;
        background: $color_gray_100;
        border-collapse: collapse;
        border-radius: 6px;
        @include drop_shadow;

        th {
          width: 114px;
          height: 24px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          padding: 0;
        }

        td {
          padding: 2px;
          width: 114px;
          height: 100px;
          background: #FFFFFF;
          border: 1px solid #EDEDF4;
          vertical-align: top;
          overflow: hidden;

          .el-calendar-day {
            height: 100%;
            padding: 0;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #252525;
            cursor: default;

            &:hover {
              background: none;
            }
          }

          &.prev .el-calendar-day, &.next .el-calendar-day {
            color: $color_gray_600;
          }

          span.date {
            display: inline-flex;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            align-items: center;
            justify-content: center;
          }

          &.is-today span.date {
            color: $color_white;
            background:$color_dark_blue;
          }

          .event {
            display: flex;
            align-items: center;
            width: 100%;
            height: 18px;
            border-radius: 2px;
            margin-top: 2px;
            padding: 0 4px;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            cursor: pointer;

            &.not_started {
              color: $color_white;
              background: $color_draft;
            }

            &.in_progress {
              color: $color_white;
              background: $color_inprogress;
            }

            &.closed {
              color: $color_gray_800;
              background: $color_closed;
            }

            .tw_alert_icon {
              margin-right: 2px;
            }

            .tw_entity_icon {
              margin-right: 3px;
            }

            i {
              display: block;
              flex-shrink: 0;
              width: 12px;
              height: 12px;
              border-radius: 100%;
              margin-right: 3px;
              background-color: $color_gray_100;
              background-size: 10px auto;
            }

            .process {
              max-width: calc(100% - 15px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              &.hasAlert {
                max-width: calc(100% - 29px);
              }
            }

            &.reminder {
              background: $color_dark_blue;
              color: $color_white;
              margin-right: 2px;

              i {
                border-radius: 0;
                background: transparent url(../../assets/images/icons/calendar.svg) no-repeat center center / 12px auto;
              }

              time {
                font-weight: normal;
                margin-right: 4px;
                flex-shrink: 0;
              }
            }
          }

          .more_events {
            text-align: right;
            margin-top: 1px;
            line-height: 1;
            span {
              display: inline-flex;
              align-items: center;
              padding-right: 16px;
              background: url(../../assets/images/icons/icon_arrow_right_blue.svg) no-repeat right center;
              background-size: 16px auto;
              color: $color_dark_blue;
              font-size: 10px;
              line-height: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }

  }
  .event_detail {
    width: 248px;
    min-height: 106px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $color_gray_600;
    text-align: left;
    border-radius: 6px;
    @include drop_shadow;
    cursor: pointer;

    &.not_started {
      border-left: 6px solid $color_draft;
    }

    &.in_progress {
      border-left: 6px solid $color_inprogress;
    }

    &.closed {
      border-left: 6px solid $color_closed;
    }

    &.reminder {
      border-left: 6px solid $color_dark_blue;
    }

    .inner {
      width: 100%;
      height: 100%;
      padding: 10px 10px 6px 10px;
      background: $color_white;
      border-radius: 0 6px 6px 0;
    }

    .icons{
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .tw_entity_icon {
        background-color: $color_gray_100;
      }
      .tw_alert_icon {
        margin-right: 8px;
      }
    }

    .process {
      display: block;
      color: $color_gray_800;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.reminder {
      word-break: break-all;
      .icons {
        i {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 4px;
          background: url(../../assets/images/icons/calendar_blue.svg) no-repeat center center / 18px auto;
        }
        
      }
      .process {
        margin-top: 4px;
        margin-bottom: 8px;
      }

      .reminder_text {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        max-height: 36px;
        -webkit-box-orient: vertical;
      }
    }
  }
</style>

<style lang="scss">
  .el-dropdown-menu.el-popper.event_dropdown {
    padding: 0!important;
    margin-top: 4px;
    z-index: 10!important;
  }
</style>
